// libs
import { styled } from '@nykaa/ui-components';

// helpers
import { getAkamaiUrl } from 'shared/helpers/utils';

// constants
import { DWEB_MAX_WIDTH, FONTS, BREAKPOINTS } from 'shared/styles/base';

export const INSTA_ICO = getAkamaiUrl(
  'https://www.nykaafashion.com/pub/media/wysiwyg/homepagemiddle/icons-instagram-footer.svg'
);

export const FB_ICO = getAkamaiUrl(
  'https://www.nykaafashion.com/pub/media/wysiwyg/homepagemiddle/icons-facebook-footer.svg'
);

export const Container = styled.div`
  background-color: #f3f2f2;
  padding: ${({ theme }) => `${theme.spacing.spacing120} 0 ${theme.spacing.spacing160} 0`};

  .lk-content {
    display: flex;
    max-width: ${DWEB_MAX_WIDTH}px;
    font-family: ${FONTS.BASE_FAMILY};
    margin: 0 auto;
    padding: ${({ theme }) => `0 ${theme.spacing.spacing50}`};

    @media (max-width: ${BREAKPOINTS.MOBILE}px) {
      display: block;
    }
  }

  .lk-section {
    flex: 1;
    color: #000;
  }

  .lk-section-2 {
    flex: 3;
  }

  .lk-title {
    font-weight: 600;
    margin-bottom: ${({ theme }) => theme.spacing.spacing100};
    text-transform: uppercase;
    ${({ theme }) => theme.typography.subTitleLarge};

    @media (max-width: ${BREAKPOINTS.MOBILE}px) {
      display: none;
    }
  }

  .lk-list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    flex-flow: column;
    flex-wrap: wrap;
    ${({ theme }) => theme.typography.subTitleMedium};

    @media (min-width: ${BREAKPOINTS.TABLET}px) {
      max-height: 90px;
    }
  }

  .lk-itm {
    margin-bottom: ${({ theme }) => theme.spacing.spacing50};

    @media (min-width: ${BREAKPOINTS.TABLET}px) {
      &:nth-of-type(3) {
        margin: 0;
      }
    }
  }

  .lk-anc {
    color: #000;
    text-decoration: none;
  }

  .lk-social {
    display: flex;
  }

  .lk-icon {
    display: block;
    height: 24px;
    width: 24px;
    margin-right: ${({ theme }) => theme.spacing.spacing50};
    text-decoration: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .ipad-hide {
    @media (max-width: ${BREAKPOINTS.TABLET}px) {
      display: none;
    }
  }

  .mobile-only {
    @media (min-width: ${BREAKPOINTS.TABLET}px) {
      display: none;
    }
  }
`;
