export const TEXTS = {
  DOWNLOAD_APP: 'Download our App',
  FOR_QUERIES: 'FOR ANY HELP, YOU MAY CALL US AT',
  CONTACT: '1800-266-3333',
  AVAILABILITY: '(Monday to Saturday: 10 am - 10 pm, Sunday: 10 am - 7 pm)',
  HELP: 'Help',
  QUICKLINKS: 'Quicklinks',
  TERMS: 'Terms & Conditions',
  FOLLOW_US: 'Follow Us',
  GOOGLE_LINK_NAME: 'Google Play Store',
  APPLE_LINK_NAME: 'Apple App Store',
};
export const APP_PROMO = '/app-promo';
export const GOOGLE_PLAYSTORE_LINK = 'https://play.google.com/store/apps/details?id=com.fsn.nds';
export const GOOGLE_PLAYSTORE_IMG =
  'https://www.nykaafashion.com/pub/media/wysiwyg/homepagemiddle/googlePlay.png';

export const APL_APPSTORE_LINK =
  'https://itunes.apple.com/in/app/nykaa-design-studio/id1439872423?mt=8';
export const APL_APPSTORE_IMG =
  'https://www.nykaafashion.com/pub/media/wysiwyg/homepagemiddle/appleStore.png';

export const ICON_DIMENSION = 24;
export const ICON_SIZES = {
  DWEB: '20px',
  MWEB: '20px',
};

export const TITLES = {
  EXPLORE_NYKAA_FASHION: 'Explore Nykaa Fashion',
};
