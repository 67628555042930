// libs
import React, { memo } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// constants
import { TEXTS } from 'shared/components/Footer/constants';

// styles
import { Container } from './styles';

//icons
import InstagramIcon from '@nykaa/ui-components/Icons/social-instagram-filled';
import FacebookIcon from '@nykaa/ui-components/Icons/social-facebook-filled';
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

//types
interface Item {
  text: string;
  link: string;
  mobileOnly: boolean;
}

const SocialLinks = ({ items }: { items: Item[] }) => (
  <div className="lk-social">
    {!isEmpty(items) &&
      items.map((listItem, i: number) => (
        <a href={listItem.link} aria-label={listItem.text} rel="noopener noreferrer" key={i}>
          {listItem.text === 'Instagram' ? <InstagramIcon /> : <FacebookIcon />}
        </a>
      ))}
  </div>
);

const LinkList = ({ items }: { items: Item[] }) => (
  <ul className="lk-list">
    {items.map((listItem, i: number) => (
      <li
        className={`${listItem.mobileOnly ? 'mobile-only' : ''} lk-itm`}
        data-at={listItem.text === TEXTS.TERMS ? 'terms-conditions' : ''}
        key={i}
      >
        <a className="lk-anc" href={listItem.link} rel="noopener noreferrer">
          {listItem.text}
        </a>
      </li>
    ))}
  </ul>
);

const Links = () => {
  const linksConfig = get(useRemoteConfig('footerSection'), 'linksConfig', []);
  if (isEmpty(linksConfig)) return null;

  return (
    <Container>
      <div className="lk-content">
        {linksConfig.map((data) => {
          const sectionClass = `lk-section ${data.doubleWidthSection ? 'lk-section-2 ' : ''}${
            data.ipadHide ? 'ipad-hide' : ''
          }`;
          return (
            <div className={sectionClass} key={data.title}>
              <div className="lk-title">{data.title}</div>
              {data.isSocial ? <SocialLinks items={data.items} /> : <LinkList items={data.items} />}
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default memo(Links);
