// libs
import React, { memo } from 'react';
import { styled } from '@nykaa/ui-components';
import get from 'lodash/get';

// constamts
import { DWEB_MAX_WIDTH, MEDIA_BREAKPOINTS, BREAKPOINTS } from 'shared/styles/base';

// components
import DownloadApp from './components/DownloadApp';
import ForQueries from './components/ForQueries';

//hooks
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

const Container = styled.div`
  background: #333;
  padding: ${({ theme }) => theme.spacing.spacing120} 0;

  .content {
    display: flex;
    max-width: ${DWEB_MAX_WIDTH}px;
    margin: 0 auto;
    color: #fff;
    font-family: inherit;
    padding: ${({ theme }) => `0 ${theme.spacing.spacing50}`};

    ${MEDIA_BREAKPOINTS.TABLET} {
      flex-flow: row-reverse;
    }

    @media (max-width: ${BREAKPOINTS.TABLET}px) {
      flex-wrap: wrap;
    }
  }
`;

interface ISubcriptionProps {
  isDownloadAppSectionVisible: boolean;
}

function Subscription({
  isDownloadAppSectionVisible = true,
}: ISubcriptionProps) {
  const showAppDownload = get(useRemoteConfig('footerSection'), 'showAppDownload', false);

  if (!showAppDownload) return null;
  return (
    <Container>
      <div className="content">
        {isDownloadAppSectionVisible && <DownloadApp />}
        <ForQueries />
      </div>
    </Container>
  );
}

export default memo(Subscription);
