// libs
import React, { memo } from 'react';
import { styled } from '@nykaa/ui-components';
import { getDomainConfig } from 'shared/domainConfig/helpers';

const Container = styled.div`
  height: auto;
  text-align: center;
  ${({ theme }) => `
    background: ${theme.colors.secondary};
    color: ${theme.colors.white};
    padding: ${theme.spacing.spacing80} ${theme.spacing.spacing40} ${theme.spacing.spacing60};
  `};
  ${({ theme }) => theme.typography.bodySmall};
`;

function Terms() {
  const { COPYRIGHT } = getDomainConfig();

  return <Container>{COPYRIGHT}</Container>;
}

export default memo(Terms);
