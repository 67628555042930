export default [
  {
    title: 'Women',
    links: [
      {
        href: '/women/indianwear/c/4',
        text: "Women's Indianwear",
      },
      {
        href: '/women/westernwear/c/3',
        text: "Women's Westernwear",
      },
      {
        href: '/women/bags/c/89',
        text: 'Bags',
      },
      {
        href: '/women/footwear/c/3528',
        text: "Women's Footwear",
      },
      {
        href: '/women/jewellery/c/77',
        text: "Women's Jewellery",
      },
      {
        href: '/women/lingerie/c/3946',
        text: 'Lingerie',
      },
      {
        href: '/women/sportswear/c/4015',
        text: "Women's Sportswear",
      },
      {
        href: '/women/lingerie/sleepwear/c/3986',
        text: "Women's Sleep & lounge",
      },
      {
        href: '/women/accessories/watches/c/4641',
        text: "Women's Watches",
      },
      {
        href: '/women/accessories/c/104',
        text: 'Fashion Accessories',
      },
    ],
  },
  {
    title: 'Men',
    links: [
      {
        href: '/men/topwear/c/6824',
        text: 'Topwear',
      },
      {
        href: '/men/bottoms/c/6834',
        text: 'Bottomwear',
      },
      {
        href: '/men/ethnicwear/c/6841',
        text: 'Ethnicwear',
      },
      {
        href: '/men/footwear/c/6857',
        text: "Men's Footwear",
      },
      {
        href: '/men/personal-accessories/c/6879',
        text: "Men's Accessories",
      },
      {
        href: '/men/innerwear-sleepwear/c/6850',
        text: 'Innerwear & Sleepwear',
      },
      {
        href: '/men/watches/c/6875',
        text: "Men's Watches",
      },
      {
        href: '/men/bags/c/6867',
        text: 'Bags & Backpacks',
      },
      {
        href: '/men/sports-and-athleisure/c/6888',
        text: 'Athleisure',
      },
      {
        href: '/sports-fitness-equipment/c/11219',
        text: 'Sports & Fitness',
      },
    ],
  },
  {
    title: 'Kids',
    links: [
      {
        href: '/kids/indianwear/c/6267',
        text: 'Kids Indianwear',
      },
      {
        href: '/kids/westernwear/c/6282',
        text: 'Kids Westernwear',
      },
      {
        href: '/kids/footwear/c/6333',
        text: 'Kids Footwear',
      },
      {
        href: '/kids/accessories/jewelry/c/6348',
        text: 'Kids Jewellery',
      },
      {
        href: '/kids/feeding/c/6351',
        text: 'Feeding',
      },
      {
        href: '/kids/sportswear/c/6298',
        text: 'Kids Sportswear',
      },
      {
        href: '/kids/sleepwear/c/6372',
        text: 'Kids Sleepwear',
      },
      {
        href: '/kids/accessories/c/6343',
        text: 'Kids Accessories',
      },
      {
        href: '/kids/toys-and-games/c/6382',
        text: 'Toys & Games',
      },
      {
        href: '/kids/innerwear/c/6329',
        text: 'Innerwear',
      },
    ],
  },
  {
    title: 'Luxe',
    links: [
      {
        href: '/luxe-indian-wear/c/12039',
        text: 'Luxury Ethnic Wear',
      },
      {
        href: '/luxe-western-wear/c/12038',
        text: 'Luxury Westernwear',
      },
      {
        href: '/luxe-footwear/c/12041',
        text: 'Luxury Footwear',
      },
      {
        href: '/luxe-bags/c/12042',
        text: 'Luxury Bags',
      },
      {
        href: '/luxe-accessories/c/12043',
        text: 'Luxury Accessories',
      },
      {
        href: '/luxe-watches/c/12200',
        text: 'Luxury Watches',
      },
      {
        href: '/luxe-home-edit/c/12045',
        text: 'Luxury Home',
      },
      {
        href: '/luxe-jewellery/c/12040',
        text: 'Luxury Jewellery',
      },
      {
        href: '/luxe-summer-collection/c/12114',
        text: 'Summer Collection',
      },
      {
        href: '/fluid-flowing-luxe/c/6002',
        text: 'Luxury Dresses',
      },
    ],
  },
  {
    title: 'Top Brands',
    links: [
      {
        href: '/designers/puma/c/4886',
        text: 'Puma',
      },
      {
        href: '/designers/vero-moda/c/4188',
        text: 'Vero Moda',
      },
      {
        href: '/designers/w/c/4038',
        text: 'W',
      },
      {
        href: '/designers/biba/c/5274',
        text: 'Biba',
      },
      {
        href: '/designers/forever-new/c/4495',
        text: 'Forever New',
      },
      {
        href: '/designers/skechers/c/6477',
        text: 'Skechers',
      },
      {
        href: '/designers/fablestreet/c/1970',
        text: 'Fablestreet',
      },
      {
        href: '/designers/only/c/4189',
        text: 'Only',
      },
      {
        href: '/designers/autumnlane/c/12071',
        text: 'Autumnlane',
      },
      {
        href: '/designers/cider/c/15204',
        text: 'Cider',
      },
      {
        href: '/designers/accessorize-london/c/6748',
        text: 'Accessorize London',
      },
    ],
  },
  {
    title: 'House of Nykaa',
    links: [
      {
        href: '/twenty-dresses-by-nykaa-fashion/c/4240',
        text: 'Twenty Dresses By Nykaa Fashion',
      },

      {
        href: '/designers/nykd-by-nykaa/c/7059',
        text: 'Nykd by Nykaa',
      },
      {
        href: '/designers/rsvp/c/4706',
        text: 'RSVP by Nykaa Fashion',
      },
      {
        href: '/designers/gajra-gang/c/10009',
        text: 'Gajra Gang by Nykaa Fashion',
      },
      {
        href: '/designers/likha/c/11935',
        text: 'Likha by Nykaa Fashion',
      },
      {
        href: '/designers/mixt-by-nykaa-fashion/c/15963',
        text: 'Mixt by Nykaa Fashion',
      },
      {
        href: '/designers/iykyk-by-nykaa-fashion/c/11495',
        text: 'IYKYK',
      },
      {
        href: '/designers/kica/c/4629',
        text: 'Kica',
      },
      {
        href: '/designers/pipa-bella-by-nykaa-fashion/c/3771',
        text: 'Pipa Bella By Nykaa Fashion',
      },
      {
        href: '/designers/azai-jewellery-by-nykaa-fashion/c/13718',
        text: 'Azai by Nykaa Fashion',
      },
      {
        href: '/designers/twig-twine/c/12291',
        text: 'Twig & Twine',
      },
      {
        href: '/designers/gloot/c/13564',
        text: 'Gloot',
      },
      {
        href: '/designers/nyri/c/14963',
        text: 'Nyri',
      },
    ],
  },
];
