// lib
import { connect } from 'react-redux';

// component
import Subscription from './Subscription';

// redux selectors
import { isFooterDownloadAppSectionVisible } from 'shared/store/app/selectors';

const mapStateToProps = (state: any) => {
  return {
    isDownloadAppSectionVisible: isFooterDownloadAppSectionVisible(state),
  };
};

export default connect(mapStateToProps)(Subscription);
