// libs
import React, { memo } from 'react';
import { styled } from '@nykaa/ui-components';

// constants
import { TEXTS } from 'shared/components/Footer/constants';
import { FONTS } from 'shared/styles/base';

import SubSection from './SubSection';

const Container = styled.div`
  font-family: ${FONTS.BASE_FAMILY};
  ${({ theme }) => theme.typography.bodySmall};
  color: #fff;
`;

const PhoneNo = styled.div`
  ${({ theme }) => theme.typography.labelMedium};
  color: #fff;
`;

function NewsLetter() {
  return (
    <SubSection title={TEXTS.FOR_QUERIES}>
      <Container>
        <PhoneNo>{TEXTS.CONTACT}</PhoneNo>
        <div>{TEXTS.AVAILABILITY}</div>
      </Container>
    </SubSection>
  );
}

export default memo(NewsLetter);
