// libs
import React, { memo } from 'react';
import { styled } from '@nykaa/ui-components';

// global components
import BaseImage from 'shared/components/Image';

// components
import SubSection from './SubSection';

// helpers
import { getAkamaiUrl } from 'shared/helpers/utils';

// constants
import {
  TEXTS,
  GOOGLE_PLAYSTORE_IMG,
  GOOGLE_PLAYSTORE_LINK,
  APL_APPSTORE_LINK,
  APL_APPSTORE_IMG,
} from 'shared/components/Footer/constants';

const IMG_HEIGHT = 33;
const IMG_WIDTH = 112;

// styles
const Container = styled.div`
  display: flex;

  .dl-anc {
    display: block;
    margin-right: ${({ theme }) => theme.spacing.spacing100};
  }
`;

const imgClassName = 'download-app-img';
const Image = styled(BaseImage)`
  height: ${IMG_HEIGHT}px;
  width: ${IMG_WIDTH}px;
  background: transparent;

  & .${imgClassName} {
    height: ${IMG_HEIGHT}px;
    width: ${IMG_WIDTH}px;
  }
`;

function DownloadApp() {
  return (
    <SubSection title={TEXTS.DOWNLOAD_APP}>
      <Container>
        <a
          className="dl-anc"
          aria-label={TEXTS.GOOGLE_LINK_NAME}
          href={GOOGLE_PLAYSTORE_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            height={IMG_HEIGHT}
            width={IMG_WIDTH}
            src={getAkamaiUrl(GOOGLE_PLAYSTORE_IMG, { height: IMG_HEIGHT * 2 })}
            alt={TEXTS.GOOGLE_LINK_NAME}
            imgClassName={imgClassName}
          />
        </a>
        <a
          className="dl-anc"
          aria-label={TEXTS.APPLE_LINK_NAME}
          href={APL_APPSTORE_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            height={IMG_HEIGHT}
            width={IMG_WIDTH}
            src={getAkamaiUrl(APL_APPSTORE_IMG, { height: IMG_HEIGHT * 2 })}
            alt={TEXTS.APPLE_LINK_NAME}
            imgClassName={imgClassName}
          />
        </a>
      </Container>
    </SubSection>
  );
}

export default memo(DownloadApp);
