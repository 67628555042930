// libs
import React, { memo } from 'react';
import { styled } from '@nykaa/ui-components';

// constants
import { FONTS } from 'shared/styles/base';

// styles
const Container = styled.div`
  color: #fff;
  font-family: ${FONTS.BASE_FAMILY};
  flex: 1 0 auto;

  @media (max-width: 580px) {
    margin-top: ${({ theme }) => theme.spacing.spacing100};

    &:first-of-type {
      margin-top: 0;
    }
  }

  .title {
    ${({ theme }) => theme.typography.buttonMedium};
    color: #fff;
    margin-bottom: ${({ theme }) => theme.spacing.spacing60};
    text-transform: uppercase;
  }
`;

// defs
interface ISubSectionProps {
  title: string;
  isTitleCaps?: boolean;
  hideOnIpad?: boolean;
  children: React.ReactNode;
}

function SubSection({ title, children }: ISubSectionProps) {
  return (
    <Container>
      <div className="title">{title}</div>
      {children}
    </Container>
  );
}

export default memo(SubSection);
